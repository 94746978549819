<template>
  <v-form v-model="valid">
    <v-container>
      <v-row  justify ="center">
        <v-col align="center"
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Name"
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="website"
            :rules="webRules"
            label="Website"
            outlined
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
        class="mx-2"
        fab
        dark
        x-large
        color="black"
        @click="Submit">
          Submit
        </v-btn>
      </v-row>
      <v-row v-if="!valid" style="margin-top:55px" justify="center">
        <v-alert
          dismissible
          elevation="11"
          type="info"
        >
        Name and email are required
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { getDatabase, ref, set } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
export default {
    data: () => ({
      valid: false,
      db: getDatabase(),
      name: '',
      website: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 100 || 'Name must be less than 100 characters',
      ],
      webRules: [
        v => v.length <= 100 || 'Address must be less than 100 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }),
    methods: {
      Submit() {
        if(this.valid) {
          this.$router.push("greet_and_grow")
          const db = getDatabase();
          
          set(ref(db, 'prospects/' + uuidv4()), {
            name: this.name,
            email: this.email,
            website: this.website
          });

        } 

      }
    }
  }
</script>
