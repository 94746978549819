<template>
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-img
          alt="KRP Photography"
          class="shrink mt-1"
          contain
          min-width="225"
          src="https://krpphotography.com/img/w3images/krp.png"
          width="100"
        />
      </v-row>
    </v-container>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
