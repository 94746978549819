import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

var firebaseConfig = {
  apiKey: "AIzaSyDfEwCqwIIUWX3pcq3XVES-GzE3OjnEBvw",
  authDomain: "krp-photo.firebaseapp.com",
  databaseURL: "https://krp-photo.firebaseio.com",
  projectId: "krp-photo",
  storageBucket: "krp-photo.appspot.com",
  messagingSenderId: "361559654465",
  appId: "1:361559654465:web:1761c835e0b465944d46c0",
  measurementId: "G-Q8S4SH5FP0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  firebase,
  render: h => h(App)
}).$mount('#app')
